<template>
  <div class="profile-page">
    <el-row :gutter="30">
      <el-col :lg="6" :md="8" :sm="24">
        <div
          class="profile-card text-center"
          shadow="never"
          style="max-width: 400px; margin-bottom: 20px"
        >
          <div class="my-3">
            <el-tooltip
              :content="
                getUserProfilePicture && getUserProfilePicture.length > 20
                  ? 'Update Profile Picture'
                  : 'Add Profile Picture'
              "
            >
              <img
                v-if="
                  getUserProfilePicture && getUserProfilePicture.length > 20
                "
                :src="getUserProfilePicture"
                alt="Avatar"
                width="120"
                style="border-radius: 50%"
                class="inline-block"
                @click="outerVisible = true"
              />
              <img
                v-else
                src="@/assets/img/avatar.svg"
                alt="Avatar"
                width="120"
                style="border-radius: 50%"
                class="inline-block"
                @click="outerVisible = true"
              />
            </el-tooltip>
            <span
              style="margin-top: 20px; margin-left: -45px"
              v-if="getUserProfilePicture && getUserProfilePicture.length > 20"
            >
              <!-- show delete icon -->
              <el-button
                style="background-color: ivory"
                type="plain"
                size="mini"
                circle
                @click="deleteProfile()"
              >
                <img width="12px" src="@/assets/Trash.svg" />
              </el-button>
            </span>
          </div>
          <hr />

          <div class="list-group text-left">
            <span :class="getClass('personal-information')" @click="goTo('personal-information')"><i
                class="el-icon-user"></i> Personal Information</span>
            <span :class="getClass('change-password')" @click="goTo('change-password')"><i class="el-icon-lock"></i>
              Change Password</span>
              <span :class="getClass('two-step-verification')" @click="goTo('two-step-verification')"><i class="el-icon-lock"></i>
                Two-Step Verification</span>
            <span :class="getClass('signature')" @click="goTo('signature')"><i class="el-icon-edit"></i> Signature</span>
            <!-- this.isAdmin()|| -->
            <span
              v-if="
                (getActiveWorkspace &&
                  getActiveWorkspace.company_id &&
                  getUserType &&
                  this.isOwner()) ||
                this.isIndividualWorkspace(this.getActiveWorkspace)
              "
              :class="getClass('subscription')"
              @click="goTo('subscription')"
              ><i class="el-icon-box"></i> Subscription</span
            >
            <span
              v-if="
                getActiveWorkspace &&
                getActiveWorkspace.company_id &&
                getUserType &&
                (this.isAdmin() || this.isOwner()) &&
                !this.isIndividualWorkspace(this.getActiveWorkspace)
              "
              :class="getClass('companyProfile')"
              @click="goTo('companyProfile')"
              ><i class="el-icon-suitcase"></i> Company Profile</span
            >

            <!-- :to="{
              name: 'manage-permissions',
            }" -->
            <span
              v-if="
                getActiveWorkspace &&
                getActiveWorkspace.company_id &&
                getUserType &&
                !this.isIndividualWorkspace(this.getActiveWorkspace) &&
                ((getUserType.name &&
                  this.isOwner() &&
                  this.isWorkspaceIsBusiness(getActiveWorkspace)) ||
                  checkIsSuperAdminOrNotForProfileManagementPermissions(
                    'managePermissions'
                  ))
              "
              :class="getClass('manage-permissions')"
              @click="goTo('manage-permissions')"
              ><i class="el-icon-key"></i> Manage Permissions</span
            >
            <span
              :class="getClass('menuManagement')"
              @click="goTo('/menu-management', true)"
              v-if="
                getActiveWorkspace &&
                getActiveWorkspace.company_id &&
                getUserType &&
                !this.isIndividualWorkspace(this.getActiveWorkspace) &&
                ((getUserType.name &&
                  this.isOwner() &&
                  this.isWorkspaceIsBusiness(getActiveWorkspace)) ||
                  checkIsSuperAdminOrNotForProfileManagementPermissions(
                    'menuManagement'
                  ))
              "
            >
              <i class="el-icon-more-outline"></i>

              Menu Management
            </span>
            <span
              :class="getClass('menuManagement')"
              @click="goTo('/roles', true)"
              v-if="
                getActiveWorkspace &&
                getActiveWorkspace.company_id &&
                getUserType &&
                !this.isIndividualWorkspace(this.getActiveWorkspace) &&
                getUserType.name &&
                this.isOwner() &&
                this.isWorkspaceIsBusiness(getActiveWorkspace)
              "
            >
              <i class="el-icon-user"></i>

              Role Management
            </span>
            <span
              :class="getClass('remove-workspace')"
              @click="goTo('remove-workspace')"
              v-if="
                getActiveWorkspace &&
                getActiveWorkspace.company_id &&
                getUserType &&
                !this.isIndividualWorkspace(this.getActiveWorkspace) &&
                ((getUserType.name &&
                  this.isOwner() &&
                  this.isWorkspaceIsBusiness(getActiveWorkspace)) ||
                  checkIsSuperAdminOrNotForProfileManagementPermissions(
                    'roleManagement'
                  ))
              "
            >
              <i class="el-icon-user"></i>

              Remove Workspace
            </span>
          </div>
        </div>
      </el-col>
      <el-col :lg="18" :md="16" :sm="24">
        <!-- {{this.isOwner()}} -->
        <component :is="layout">
          <router-view :layout.sync="layout" />
        </component>
      </el-col>
    </el-row>
    <dialog-component
      :width="getIsMobile ? '100%' : '100%'"
      :height="getIsMobile ? '100%' : '100%'"
      :title="'Update Profile Picture'"
      :visible="outerVisible"
      @before-close="closeDialog"
    >
      <vue-anka-cropper
        class="upload-file"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'circle',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: false,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          resultMimeType: 'image/png',
          resultMimeType: 'image/jpg',
          resultMimeType: 'image/gif',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-preview="uploadFile"
        @cropper-file-selected="uploadFile"
        @cropper-saved="onUpdate"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            resetImgData();
          "
          plain
          >Cancel</el-button
        >
        <el-button type="primary" @click="onUpdate()">Save</el-button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";

import PermissionsHelper from "@/mixins/permissionsHelper";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
export default {
  name: "ProfileBody",
  data() {
    return {
      layout: "div",
      outerVisible: false,
      imgData: "",
      previewImage: "",
      profileData: null,
      upLoadEffect: false,
      buttondisale: true,
      planDetails: {},
    };
  },

  mixins: [MobileRelatedHelper, PermissionsHelper],

  mounted() {
    this.getUserSavedProfilePicture();
    this.getSubscriptionHistory();
  },
  computed: {
    ...mapGetters("auth", [
      "getUpdateProfilePicStatus",
      "getupdateprofilepicture",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    getUserProfilePicture() {
      if (this.getupdateprofilepicture) {
        return this.getupdateprofilepicture;
      } else {
        return null;
      }
    },
    getClass() {
      return (routeName) => {
        if (routeName == this.$route.name) {
          return "list-group-item list-group-item-action active";
        } else return "list-group-item list-group-item-action";
      };
    },
  },
  components: { vueAnkaCropper },

  methods: {
    closeDialog() {
      this.outerVisible = false;
    },

    async getSubscriptionHistory() {
      try {
        this.loading = true;
        let email = "";
        await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", {
          email,
        });
        if (
          this.getPaymentsHistory &&
          this.getPaymentsHistory.planFeatures &&
          this.getPaymentsHistory.planFeatures.length
        ) {
          this.planDetails =
            this.getPaymentsHistory &&
            this.getPaymentsHistory.planFeatures &&
            this.getPaymentsHistory.planFeatures[0];
        }
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } catch (error) {
        this.loading = false;
        console.log("getSubscriptionHistory", error);
      }
    },

    goTo(componentName, status = false) {
      let allowedRoutes = [
        "personal-information",
        "change-password",
        "signature",
        "subscription",
        "remove-workspace",
      ];
      console.log(
        "allowedRoutes.indexOf(componentName)",
        allowedRoutes.indexOf(componentName)
      );
      if (this.planDetails.diff || allowedRoutes.indexOf(componentName) >= 0) {
        if (status) {
          this.$router.push({
            path: componentName,
            query: {
              key: Math.floor(Math.random() * 100000),
            },
          });
        } else {
          this.$router.push({
            name: componentName,
            query: {
              key: Math.floor(Math.random() * 100000),
            },
          });
        }
      } else {
        this.$message({
          message: "Your package has expired.",
          type: "error",
        });
      }
    },

    resetImgData() {
      this.imgData = "";
    },
    async deleteProfile() {
      this.$confirm("Are you sure you want to delete the profile picture?")
        .then(async () => {
          // let isRemovingProfilePicture = false;
          let params = {
            file: "",
            isRemovingProfilePicture: true,
          };
          await this.$store.dispatch(
            "auth/fetchupdateProfilePicStatus",
            params
          );
          if (this.getUpdateProfilePicStatus) {
            this.resetImgData();
            this.$notify({
              title: "Success",
              message: "Profile picture deleted successfully.",
              type: "success",
            });
            window.location.reload();
          } else {
            this.$notify({
              title: "Error",
              message: "Error in deleting profile",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    async onUpdate() {
      this.upLoadEffect = true;
      let params = {
        file: this.imgData,
      };
      await this.$store.dispatch("auth/fetchupdateProfilePicStatus", params);
      if (this.getUpdateProfilePicStatus) {
        this.$notify({
          title: "Success",
          message: "Profile Picture Updated Successfully",
          type: "success",
        });
        this.outerVisible = false;
        this.upLoadEffect = false;
        this.buttondisale = true;
      } else {
        this.$notify({
          title: "Error",
          message: "Error while updating profile",
          type: "error",
        });
        this.upLoadEffect = false;
        this.buttondisale = true;
      }
    },
    async getUserSavedProfilePicture() {
      await this.$store.dispatch("auth/fetchupdateProfilePicture");
    },
    fileError() {
      this.$alert("Maximum Upload file size: 8 MB");
    },
    uploadFile(file) {
      this.imgData = file;
      this.profileData = file;
      setTimeout(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        this.imgData = doc.querySelector("img").src;
      }, 1000);
    },

    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}
.profile-page {
  .profile-card {
    border-radius: 4px;
    border: 1px solid #ebeef5;
    margin: 0 -1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1);
    .list-group {
      .list-group-item {
        border-radius: 0;
        padding: 1em;
        border: none;
        color: #9299b8;
        cursor: pointer;

        [class^="el-icon"] {
          margin-right: 10px;
        }
        &.active {
          color: var(--primary-color);
          border-left: 2px solid var(--primary-color);
        }
      }
    }
  }
}

.profile-card {
  transition: transform 0.5s ease-in-out;
  transition: box-shadow 0.5s ease-in-out;
}
.el-button.is-plain:hover {
  background: var(--primary-contrast-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.el-button--text,
.el-button--text:hover {
  border: none !important;
}
</style>